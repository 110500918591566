
  import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { HasProperyPart } from "@/mixins/has-property-part";
  import { PropertyType } from "@/enums/property";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      ValidationProvider,
    },
  })
  export default class PropertyPartType extends Mixins(HasProperyPart) {
    @Prop({ default: null }) value!: PropertyType | null;

    propertyTypes = PropertyType;

    localValue: PropertyType | null = null;

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyType | null) {
      this.localValue = newValue;
    }
  }
